<template>
    <div class="wap-box">
        <div class="count flex">
            <h4>客户分析</h4>
            <div>
                <a-radio-group v-model="choose" @change="chooseChange">
                    <a-radio-button v-for="(item,index) in chooseArr" :key="index" :value="item.value">
                        {{item.label}}
                    </a-radio-button>
                </a-radio-group>
            </div>
        </div>
        <div class="count flex" style="margin:20px 0;align-items: center">
            <a-select v-model="allValue" @change="timeChange(allValue, 1)">
                <a-select-option
                    v-for="(item, index) in timeArr"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                >
            </a-select>
            <a-date-picker class="head_date" v-model="timeStart" @change="onChange()"/>至<a-date-picker class="head_date" v-model="timeEnd" @change="onChange()"/>
        </div>
        <div class="flex">
            <a-radio-group v-model="targettype" @change="chooseChange" style="margin-right:5px">
                <a-radio-button v-for="(item, index) in targetArr" :key="index" :value="item.key">
                {{ item.value }}
                </a-radio-button>
            </a-radio-group>

            <a-select v-if="targettype == 3" v-model="executor" placeholder="请选择员工" style="width: 40vw"
                show-search @change="chooseChange">
                <a-select-option v-for="(item, index) in clientList" :key="index" :value="item.userid">
                {{ item.name }}
                </a-select-option>
            </a-select>
            <a-select v-if="targettype == 2" v-model="teamor" placeholder="请选择部门" style="width: 40vw"
                show-search @change="chooseChange">
                <a-select-option v-for="(item, index) in teamList" :key="index" :value="item.did">
                {{ item.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="pannel">
            <div class="flex countTitle">
                <h4>客户数据</h4>
            </div>
            <div >
                <div class="datas-common" id="corpNew"></div>
                <div class="datas-common" id="corpFollow"></div>
                <!-- new 新增 -->
                <div class="datas-common" id="corpNewPie"></div>
                <!-- unassigned 未分配-->
                <div class="datas-common" id="corpUnassignedPie"></div>
                <!-- order_ok 成交 -->
                <div class="datas-common" id="corpOrderokPie"></div>
            
                <div class="datas-common" id="corpOriginPie"></div>
                <div class="datas-common" id="corpFollowPie"></div>
   
            </div>
        </div>
        
        <div class="pannel">
            <div class="flex countTitle">
                <h4>联系人数据</h4>
            </div>
            <div>
                <div class="datas-common" id="linkman"></div>
                <div class="datas-common" id="linkmanNewPie"></div>
            </div>
        </div>
        <div class="pannel">
            <div class="flex countTitle">
                <h4>商机数据</h4>
            </div>
            <div >
                <div class="datas-common" id="chanceNew"></div>
                <div class="datas-common" id="chanceStatusPie"></div>
                <div class="datas-common" id="chanceReasonPie"></div>
            </div>
        </div>
        
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {requestXml} from '../../../assets/js/request';
let targetArr = [
    {
        key: 1,
        value: "公司",
    },
    {
        key: 2,
        value: "部门",
    },
    {
        key: 3,
        value: "个人",
    },
];
var moment = require('moment');
export default {
    name: 'envyPie',
    props: {
        voltage: Array
    },
    data() {
        return {
            executor: "", //执行人
            clientList: [], //执行人列表

            teamor: "", //团体id
            teamList: [], //团体列表
            targetArr,
            targettype: 3, //完成主体类型1公司,2团体,3个人
            start: "",
            end: "",

            allValue: 3,
            timeArr: [
                {"label":"今日","value": 1},
                {"label":"本周","value": 2},
                {"label":"本月","value": 3},
                {"label":"全年","value": 4},
            ],
            timeStart: null,
            timeEnd: null,
            choose: "D",
            chooseArr: [
                {"label":"按日","value": "D"},
                {"label":"按周","value": "W"},
                {"label":"按月","value": "M"}
            ],
            followArr: ["动态","资料变更","跟进","任务","商机"]
        }
    },
    mounted: function() {
        this.start = moment().startOf('month').format('x');
        this.end =  moment().endOf('month').format('x');
        // 获取客户分析数据
        this.getPannel();
        // 获取执行人列表
        this.getClientList();
    },
    methods: {
        // 获取 简化成员列表
        getClientList() {
            requestXml("/scrm/Staff/getMinList", "POST", (res) => {
                this.clientList = res;
            });
            requestXml("/jieb/Target/department", "POST", (res) => {
                console.log("teamListteamList");
                this.teamor = res[0].did;
                this.teamList = res;
            });
        },
        // 设置开始时间0:0:0 结束时间23:59:59
        setTime(time,type){
            if(type == 1){
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
            }else{
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
            }
            return time.getTime();
        },
        
        // window.console.log(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
        // window.console.log(moment().startOf('day').format('X'));//以10位Unix时间戳输出(秒）
        // window.console.log(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
        // window.console.log(moment().endOf('day').format('x'));//以13位Unix时间戳输出（毫秒）
        // 本周 本月 全年
        timeChange(val) {
            if (val == 1) {
                this.start = moment().startOf("day").format("x");
                this.end = moment().endOf("day").format("x");
            } else if (val == 2) {
                this.start = moment().startOf("week").add(1, "day").format("x");
                this.end = moment().endOf("week").add(1, "day").format("x");
            } else if (val == 3) {
                this.start = moment().startOf("month").format("x");
                this.end = moment().endOf("month").format("x");
            } else {
                this.start = moment().startOf("year").format("x");
                this.end = moment().endOf("year").format("x");
            }
           
            this.timeStart='';
            this.timeEnd='';
            this.getPannel();
           
        },
        onChange() {
            if(this.timeStart){
                this.start = moment(this.timeStart).startOf('day').format("x");
            }else{
                this.start='';
            }
            if(this.timeEnd){
                this.end = moment(this.timeEnd).endOf('day').format("x");
            }else{
                this.end='';
            }
            this.getPannel();
            
        },
        chooseChange(){
            this.getPannel();
        },
        getPannel(){
            if (this.targettype == 3) {
                this.teamor = "";
            }
            if (this.targettype == 2) {
                this.executor = "";
            }
            if (this.targettype == 1) {
                this.teamor = "";
                this.executor = "";
            }
            requestXml("/scrm/Statistics/getCorp","POST",(res) => {
                // 客户
                this.drawLineChart(res.corp.new_log[0],"新增客户记录",res.corp.new_log[1],"corpNew");
                this.drawLineChart(res.corp.follow_log[0],"新增跟进记录",res.corp.follow_log[1],"corpFollow");

                let corpAll = Number(res.corp.all);
                let corpNewArr = [];
                corpNewArr[0] = {"name": "原有客户" ,"value": corpAll - Number(res.corp.new)};
                corpNewArr[1] = {"name": "新增客户" ,"value": res.corp.new};
                this.drawPieChart("新增客户数据",corpNewArr,"corpNewPie");

                let corpUnassignedArr = [];
                corpUnassignedArr[0] = {"name": "已分配客户数" ,"value": corpAll - Number(res.corp.unassigned)};
                corpUnassignedArr[1] = {"name": "未分配客户数" ,"value": res.corp.unassigned};
                this.drawPieChart("未分配客户数据",corpUnassignedArr,"corpUnassignedPie");

                let corpOrderokArr = [];
                corpOrderokArr[0] = {"name": "未成交客户数" ,"value": corpAll - Number(res.corp.order_ok)};
                corpOrderokArr[1] = {"name": "成交客户数" ,"value": res.corp.order_ok};
                this.drawPieChart("成交客户数据",corpOrderokArr,"corpOrderokPie");

                this.drawPieChart("客户来源分类",res.corp.origin_type,"corpOriginPie");
                for(let i=0;i<res.corp.follow_type.length;i++){
                    res.corp.follow_type[i].name = this.followArr[Number(res.corp.follow_type[i].name)-1]
                }
                this.drawPieChart("跟进记录分类",res.corp.follow_type,"corpFollowPie");


                // 联系人
                this.drawLineChart(res.linkman.new_log[0],"联系人",res.linkman.new_log[1],"linkman");
                let linkmanAll = Number(res.linkman.all);
                let linkmanNewArr = [];
                linkmanNewArr[0] = {"name": "原有联系人" ,"value": linkmanAll - Number(res.linkman.new)};
                linkmanNewArr[1] = {"name": "新增联系人" ,"value": res.linkman.new};
                this.drawPieChart("新增联系人数据",linkmanNewArr,"linkmanNewPie");

                this.drawLineChart(res.chance.new_log[0],"商机",res.chance.new_log[1],"chanceNew");

                
                this.drawFunnelChart("商机进度分类",res.chance.status_type,"chanceStatusPie");
                this.drawPieChart("失败商机分类",res.chance.reason_type,"chanceReasonPie");
                
            },{"start": this.start,"end": this.end,"timetype": this.choose,"department_id": this.teamor, "staff_uid":this.executor})
        },
        drawLineChart(xData,name,allData,ele) {
            var myChart = echarts.init(document.getElementById(ele));
            // 绘制图表
            myChart.setOption({
                title: {
                    text: ""
                },
                tooltip: {},
                xAxis: {
                    data: xData
                },
                yAxis: {},
                series: [{
                    name: name,
                    type: "line",
                    data: allData
                }]
            });
        },
        drawPieChart(pieName,pieAllData,pieEle) {
            var pieChart = echarts.init(document.getElementById(pieEle));
            pieChart.setOption({
                title: {
                    text: pieName,
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                },
                series: [{
                    name: "",
                    type: 'pie',
                    radius: '50%',
                    data: pieAllData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            });
        },
        drawFunnelChart(funnelName,funnelAllData,funnelEle) {
            var funnelChart = echarts.init(document.getElementById(funnelEle));
            funnelChart.setOption({
                title: {
                    text: funnelName,
                },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b} : {c}%"
                },
                legend: {},
                series: [{
                    name: "",
                    type:'funnel',
                    left: '10%',
                    top: 60,
                    //x2: 80,
                    bottom: 60,
                    width: '80%',
                    // height: {totalHeight} - y - y2,
                    min: 0,
                    max: 10,
                    minSize: '0%',
                    maxSize: '100%',
                    sort: 'descending',
                    gap: 2,
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    labelLine: {
                        length: 10,
                        lineStyle: {
                            width: 1,
                            type: 'solid'
                        }
                    },
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    emphasis: {
                        label: {
                            fontSize: 20
                        }
                    },
                    data: funnelAllData
                }]
            })
        }
    },

}
</script>
<style>
    
</style>
